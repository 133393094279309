<script setup lang="ts">
import UiTextField from '@/components/ui/TextField/index.vue';

const props = defineProps({
  isRegistration: {
    type: Boolean,
    default: false,
  },
  expiresIn: {
    type: Number,
    default: 0,
  },
  error: {
    type: String || Boolean,
    default: '',
  },
  phone: {
    type: String,
    default: '',
  },
})

const code = ref('')
const recieveNews = ref(true)
const policyAgreement = ref(false)
const policyAgreementError = ref(false)
const loading = ref(false)

const codeRef = ref<InstanceType<typeof UiTextField> | null>(null)
const focus = () => {
  nextTick(() => {
    if (codeRef.value) {
      codeRef.value.focus()
    }
  })
}
defineExpose({ focus })

const emit = defineEmits(['confirm', 'resend', 'back'])

const onConfirm = () => {
  loading.value = true
  if (props.isRegistration && !policyAgreement.value) {
    policyAgreementError.value = true
    loading.value = false
    throw new Error('Policy agreement is not set')
  }
  emit('confirm', code.value, recieveNews.value, policyAgreement.value)
}

watch(policyAgreement, () => {
  if (policyAgreement.value) {
    policyAgreementError.value = false
  }
})

watch(() => props.error, () => {
  if (props.error) {
    loading.value = false
  }
})

const expiresTime = computed(() => {
  const minutes = Math.floor(props.expiresIn / 60)
  const seconds = props.expiresIn % 60
  return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
})

const resendCode = () => {
  if (loading.value) return
  if (props.expiresIn > 0) return
  emit('resend')
}

const hiddenPhone = computed(() => {
  return props.phone.replace(/(\d{1})(\d{6})(\d{4})/, '+$1******$3')
})

const backButtonClicked = () => {
  emit('back')
}

</script>

<template lang="pug">
AuthorizationWrap(
  title="Введите код из СМС"
  :description="`Мы отправили код подтверждения на ваш номер телефона ${hiddenPhone}`"
  buttonText="Продолжить"
  @buttonClicked="onConfirm"
  :isLoading="loading"
  show-go-back-btn
  @backButtonClicked="backButtonClicked"
)
  template(v-slot)
    .fields
      UiTextField(
        placeholder="000000"
        type="number"
        title="Секретный код"
        v-model="code"
        ref="codeRef"
        :error="props.error"
        :maxLength="6"
      )
        template(#footer)
          .field-footer 
            template(v-if="expiresIn > 0") Мы отправили вам секретный код, если он не придёт в течение {{ expiresIn }} секунд, код можно&nbsp;
            template(v-else) Код можно&nbsp;
            span.new-code-btn(
                @click.prevent="resendCode"
              ) получить повторно
              
        template(#error v-if="props.error === 'Неверный код'")
          .field-footer Код введен неверно,&nbsp;
            template(v-if="expiresIn > 0") в течение {{ expiresIn }} секунд, код можно&nbsp;
            template(v-else) код можно&nbsp;
            span.new-code-btn(
              @click="resendCode"
            ) получить повторно 
  template(#footer)
    .checkbox-flex(v-if="props.isRegistration")
      UiCheckbox.first-checkbox(
        v-model="recieveNews"
        title="Хочу быть в курсе новостей"
      )
      UiCheckbox(
        v-model="policyAgreement"
        :error="policyAgreementError"
        error-text="Нам необходимо ваше согласие, таков закон."
      ) Я согласен c 
        nuxt-link(to="#") пользовательским соглашением
</template>

<style lang="scss" scoped>
.fields {
  margin-bottom: get-vw(23);
}
.checkbox-flex {
  margin-top: get-vw(11);
  margin-bottom: get-vw(55);
  .first-checkbox {
    margin-bottom: get-vw(27);
  }
}

.field-footer {
  @include text-t4-light-m("lg");
  color: $button-xs-text;
  text-align: center;
  margin-top: get-vw(5);
}

.expires-time {
  font-family: Raleway;
  font-size: get-vw(14);
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
}

.new-code-btn {
  color: $links;
  text-decoration: underline;
  cursor: pointer;
}

@include tablet {
  .field-footer {
    @include text-t11-m;
  }
}

@include mobile {
  .field-footer {
    @include text-t11-m("sm");
  }
}
</style>
