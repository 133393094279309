<script setup lang="ts">
const props = defineProps<{
  nickname: string,
  username: string
}>()

const emit = defineEmits(['confirm'])

const onClick = () => {
  const route = useRoute()
  const router = useRouter()
  if (route.fullPath === '/welcome') {
    router.push('/')
    return
  }

  emit('confirm')
}

onBeforeUnmount(() => {
  emit('confirm')
})

</script>

<template lang="pug">
authorization-wrap(title="Вы зарегистрированы!" buttonText="Супер!" @buttonClicked="onClick")
  template(v-slot)
    .auth-conf-container
      img.image-done(src="/images/login-confirmation.png")
      .new-name
        .your-name Вам присвоен ник
        .description {{ props.nickname }}!
        .username @{{ props.username }}
      .settings
        | Ваше имя, фото и другие данные можно изменить в 
        NuxtLink.settings-link(to="/dashboard") настройках профиля
</template>

<style lang="scss" scoped>
.auth-conf-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;

  .auth-content {
    display: flex;
    flex-direction: column;
  }
  .title {
    @include headline-h3-h3;
    margin-bottom: get-vw(64);
  }

  .image-done {
    width: get-vw(238);
    height: get-vw(152);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: get-vw(64)
  }

  .your-name {
    @include text-t4-regular;
    margin-right: get-vw(5);
  }

  .description {
    @include text-t4-semibold;
  }

  .settings {
    @include text-t4-regular;
    margin-bottom: get-vw(64);
  }

  .main-button {
    margin-bottom: get-vw(24);
  }
}

.politic-text {
  @include text-t4-regular;
  color: $button-xs-text;
  text-align: center;
  margin-bottom: get-vw(64);
  margin-top: 0;
}

.politic-button {
  text-decoration: underline;
  color: $links;
  cursor: pointer;
}

.buttons-flex {
  display: flex;
  justify-content: space-between;
}

.new-name {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: get-vw(36);
}

.username {
  @include text-t4-semibold;
  color: $base-600;
  width: 100%;
}

@include tablet {
  .auth-conf-container {
    .title {
      @include headline-h3-m;
      margin-bottom: get-vw(27, "md");
    }
    .description {
      @include text-t4-semibold-m;
      margin-right: get-vw(5, 'md');
    }
    .main-button {
      margin-bottom: get-vw(20, "md");
    }

    .your-name, .settings {
      @include text-t4-regular-m;
    }

    .new-name {
      margin-bottom: get-vw(45, "md");
    }

    .username {
      @include text-t4-semibold-m("md");
      width: auto;
    }
  }
}

@include mobile {
  .auth-conf-container {
    width: 100%;
    padding-left: 0;
    padding-top: get-vw(19, "sm");
    padding-bottom: get-vw(28, "sm");
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;

    .auth-content {
      height: 100%;
      padding-left: get-vw(55, "sm");
    }

    .title {
      @include headline-h3-m("sm");
      margin-bottom: get-vw(27, "sm");
    }

    .description {
      @include text-t4-semibold-m("sm");
    }

    .settings {
      @include text-t4-regular-m("sm");
      margin-bottom: get-vw(64, "sm");
    }

    .main-button {
      margin-bottom: get-vw(24, "sm");
    }

    .image-done {
      width: get-vw(100, "sm");
      height: auto;
      margin-bottom: get-vw(64, "sm");
      margin-left: auto;
      margin-right: auto;
    }

    .your-name {
      @include text-t4-regular-m("sm");
    }

    .new-name {
      margin-bottom: get-vw(36, "sm");
    }

    .username {
      @include text-t4-semibold-m("sm");
      width: auto;
    }
  }
}
</style>