<script setup lang="ts">
import UiPhoneInput from '@/components/ui/PhoneInput/index.vue';

interface Props {
  isRegistration?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(['socialLogin', 'codeSent'])

const authStore = useAuthStore()
const { isLoading } = storeToRefs(authStore)

const telRef = ref<InstanceType<typeof UiPhoneInput> | null>(null)

const focus = () => {
  nextTick(() => {
    if (telRef.value) {
      telRef.value.focus()
    }
  })
}

const errors = ref({
  tel: ''
})

const credentials = ref({
  tel: ''
})

watch(() => credentials, () => {
  errors.value.tel = ''
}, { deep: true })

watch(() => authStore.errorMessage, (newErrorMessage) => {
  errors.value.tel = newErrorMessage;
});

const onAuth = async () => {
  const { tel } = credentials.value
  if (!tel) {
    errors.value.tel = 'Введите телефон';
    return
  }
  emit('codeSent', tel)
}

const onSocialAuth = async (provider: string) => {
  authStore.toggleLoading()
  emit('socialLogin', provider) 
}

const binds = computed<{
  title: string
  description: string
  buttonText: string
  isLoading: boolean
}>(() => {
  if (props.isRegistration) {
    return {
      title: 'Завершите регистрацию',
      description: 'В ваших соцсетях не указан номер телефона, это нужно исправить',
      buttonText: 'Зарегистрироваться',
      isLoading: isLoading?.value,
    }
  }

  return {
    title: 'Привет! Мы скучали 🤩',
    description: 'Введите номер телефона для входа на сайт',
    buttonText: 'Вход/Регистрация',
    isLoading: isLoading?.value,
  }
})

defineExpose({ focus })
</script>

<template lang="pug">
authorization-wrap(v-bind="binds" @buttonClicked="onAuth" :isLoading="isLoading")
  template(v-slot)
    .fields
      ui-phone-input(
        title="Телефон"
        v-model="credentials.tel"
        ref="telRef"
        :error="errors.tel"
        @blur="authStore.resetNumberError"
      )
  template(#footer)
    p.politic-text Нажимая “{{ binds.buttonText }}” вы соглашаетесь с&nbsp;
      a.politic-button(href="/footer/policy" target="_blank") политикой конфиденциальности
    .buttons-flex(v-if="!props.isRegistration")
      UiButtonSocial.button-social(social="vk" @click="onSocialAuth('vk')")
      UiButtonSocial.button-social(social="yandex" @click="onSocialAuth('yandex')")
      UiButtonSocial.button-social(social="telegram" @click="onSocialAuth('telegram')")
</template>

<style lang="scss" scoped>

.fields {
  margin-bottom: get-vw(12);
}
.auth-login {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;

  &__content {
    /* margin-top: get-vw(374); */
  }
}
.login-button {
  margin-right: get-vw(32);
}

.logs {
  margin-right: get-vw(32);
}
.header {
  font-size: get-vw(30);
  font-weight: 700;
  padding-bottom: get-vw(68);
  margin-right: get-vw(32);

  .hello-text {
    width: 100%;
    height: get-vw(168);
  }
}

.entrance {
  width: 20.6vw;
  height: 3.1vw;
  margin-top: 2vw;
  border-radius: 75px;
  background-color: #1B958A;
  color: $white;
  border: none;
  font-size: get-vw(31.66);
  font-family: 'Raleway';
}

.politic-text {
  @include text-t4-regular;
  color: $button-xs-text;
  text-align: center;
  margin-bottom: get-vw(64);
  margin-top: 0;
}

.politic-button {
  text-decoration: underline;
  color: $links;
  cursor: pointer;
}

.buttons-flex {
  display: flex;
  justify-content: space-between;
  gap: get-vw(24);
  .button-social {
    flex: 1;
  }
}

@include tablet {
  .fields {
    margin-bottom: get-vw(5, "md");
  }
  .politic-text {
    @include text-t4-regular-m;
    margin-bottom: get-vw(40, "md");
  }

  .buttons-flex {
    gap: get-vw(18, "md");
  }
}

@include mobile {
  .politic-text {
    @include text-t4-regular-m('sm');
    margin-bottom: 0;
  }

  .buttons-flex {
    margin-top: get-vw(36, 'sm');
    gap: get-vw(24, "sm");
  }
}
</style>
