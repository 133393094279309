<script setup lang="ts">
import FooterFaqIcon from '@/assets/svg/footer-faq.svg'
import FooterCloseIcon from '@/assets/svg/icon-x.svg'
import { storeToRefs } from 'pinia';
const mainStore = useMainStore()
const { isFooterOpen } = storeToRefs(mainStore)
const { toggleFooter, closeFooter } = mainStore
const { pages } = useFAQStore()
</script>

<template lang="pug">
nuxt-link.disabled-link(to="/footer/about")
.actions
  .icon(@click.stop="toggleFooter" :class="{ active: isFooterOpen }")
    FooterFaqIcon(v-if="!isFooterOpen")
    FooterCloseIcon(v-else)
    .items(v-if="isFooterOpen" v-click-outside="closeFooter")
      .links
        nuxt-link.faq-link(v-for="page in pages" :to="`/footer/${page.route}`") {{ page.title }}
      .socials
        ui-button-social(size='sm' social='telegram')
        ui-button-social(size='sm' social='vk')
        ui-button-social(size='sm' social='youtube')
        ui-button-social(size='sm' social='zen')
</template>

<style lang="scss" scoped>
.actions {
  width: 100%;
  height: get-vw(242);
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;

  .icon {
    width: 100%;
    /* height: get-vw(56); */
    margin: get-vw(17);
    background-size: get-vw(56);
    cursor: pointer;
    /* min-width: get-vw(56);
    min-height: get-vw(56); */
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .items {
    width: get-vw(301);
    margin: 0 auto;
  }
}

.items {
  position: absolute;
  background-color: $white;
  left: get-vw(108);
  bottom: get-vw(-17);
  border-radius: get-vw(26);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-name: items-show;
}

@keyframes items-show {
  from {
    opacity: 0;
    transform: translateY(get-vw(80));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.faq-link {
  @include text-t4-semibold;
  display: block;
  text-decoration: none;
  color: $black;

  & + & {
    margin-top: get-vw(18);
  }
}
.socials {
  display: flex;
  justify-content: space-between;
  margin-left: get-vw(26);
  margin-top: get-vw(21);
  margin-right: get-vw(20);
  margin-bottom: get-vw(25);
  animation: socials-show 0.5s ease-in-out forwards;
}

.disabled-link {
  display: none;
}

@keyframes socials-show {
  from {
    opacity: 0;
    transform: translateY(get-vw(200));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.links {
  @include text-t4-semibold;
  margin-top: get-vw(20);
  margin-left: get-vw(25);
  animation: links-show 0.5s ease-in-out forwards;
}

@keyframes links-show {
  from {
    opacity: 0;
    transform: translateY(get-vw(80));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include tablet {
  .actions .icon {
    width: get-vw(40, "md");
    height: get-vw(40, "md");
    background-size: get-vw(35, "md");
    margin-bottom: get-vw(22, "md");
  }

  .items {
    bottom: get-vw(-22, "md");
    left: get-vw(65, "md");
  }
}

@include mobile {
  .actions {
    width: auto;
    height: auto;
    margin: 0;
    position: relative;
    bottom: 0;
  }
  .actions .icon {
    background: url(@/assets/svg/footer-faq-mobile.svg) center center no-repeat;
    width: get-vw(28, "sm");
    height: get-vw(28, "sm");
    background-size: get-vw(28, "sm");
    margin-bottom: get-vw(1, "sm");
    margin-left: get-vw(15, "sm");

    &.active {
      background-size: get-vw(28, "sm");
    }
  }

  .disabled-link {
    display: block !important;
    position: absolute;
    width: get-vw(28, "sm");
    height: get-vw(28, "sm");
    margin-left: get-vw(15, "sm");
    z-index: 1;
  }
  .actions .items {
    display: none;
  }

  svg {
    &:deep(path) {
      stroke-width: 2;
    }
  }
}
</style>