<script setup lang="ts">
import Chat from '@/assets/svg/chat.svg'
import Close from '@/assets/svg/icon-x.svg'
import { NuxtLink } from '#components'

const route = useRoute()
const router = useRouter()

const isChatOpen = computed(() => route.path === '/messenger')

const toggleChat = () => {
  if (isChatOpen.value) {
    if (router.options.history.state.back && router.options.history.state.back !== '/messenger') {
      navigateTo(router.options.history.state.back as string)
    } else {
      router.push('/')
    }
  }
}
</script>

<template lang="pug">
component(:is="isChatOpen ? 'button' : NuxtLink" to="/messenger" @click="toggleChat" class="chat-button")
  Chat.chat(v-if="!isChatOpen")
  Close.chat(v-else)
</template>

<style lang="scss" scoped>
.chat-button {
  border: none;
  background-color: transparent;
  padding: 0;
  width: 100%;
  position: relative;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  @include mobile {
    width: get-vw(32, "sm");
    height: get-vw(32, "sm");
    margin: 0;
    margin-right: get-vw(15, "sm");
  }
}


.chat {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  position: absolute;
  top:      0;
  left:     0;
  bottom:   0;
  right:    0;

  @include mobile {
    width: 100%;
    height: 100%;
  }
}

@include mobile {
  svg {
    &:deep(path) {
      stroke-width: 1.5;
    }
  }
}
</style>